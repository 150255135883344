export const AppConfig = {
  api_url: process.env.NEXT_PUBLIC_API_URL || 'https://api.lifeisachance.com',
  graphql_url:
    process.env.NEXT_PUBLIC_GRAPHQL_URL ||
    'https://api.lifeisachance.com/graphql',
  site_name: 'Drug-Free Sleep',
  title: 'An app that helps you sleep better',
  description:
    'Cognitive Behavioral Therapy for Insomnia (CBT-I) is the most effective treatment for chronic insomnia.',
  locale: 'en',
  configNoteId: process.env.NEXT_PUBLIC_CONFIG_NOTE_ID
    ? parseInt(process.env.NEXT_PUBLIC_CONFIG_NOTE_ID, 10)
    : 63,
  env: process.env.NEXT_PUBLIC_ENV,
};
