module.exports = {
  "props": {
    "apolloCache": {
      "Note:63": {
        "__typename": "Note",
        "id": 63,
        "userId": 1,
        "orgId": 1,
        "type": "note",
        "state": "DRAFT",
        "name": "Configuration for CB dev",
        "slug": "",
        "payload": {
          "md": "",
          "program": {
            "id": 5,
            "slug": "cb",
            "versionId": -1,
            "organizationSlug": "weller-labs"
          },
          "components": {
            "about": {
              "id": 29
            },
            "homepage": {
              "id": 62
            }
          }
        },
        "meta": {},
        "isPublic": true,
        "createdAt": "2024-05-21T22:28:10.9178",
        "updatedAt": "2024-05-21T22:38:48.431559",
        "atVersion({\"input\":{}})": {
          "__typename": "Note",
          "userId": 1,
          "orgId": 1,
          "type": "note",
          "state": "DRAFT",
          "name": "Configuration for CB dev",
          "slug": "",
          "payload": {
            "md": "",
            "program": {
              "id": 5,
              "slug": "cb",
              "versionId": -1,
              "organizationSlug": "weller-labs"
            },
            "components": {
              "about": {
                "id": 29
              },
              "homepage": {
                "id": 62
              }
            }
          },
          "meta": {},
          "isPublic": false,
          "createdAt": "2024-05-21T22:28:10.9178",
          "updatedAt": "2024-05-21T22:38:28.688873"
        }
      },
      "ROOT_QUERY": {
        "__typename": "Query",
        "note({\"id\":63})": {
          "__ref": "Note:63"
        }
      }
    }
  }
};